
import React, { useEffect, Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { handleUpdateDetail } from './actions'
import { useDispatch } from 'react-redux'
import AppUsersAppUsers from "./services/apppUsers"
import { connect } from "react-redux"
import Layout from './../src/Page/Layout'
import 'App.css';
import 'App.scss';
import { IconWallet, IconProfileIcon, IconRechargeHistory, BetHistoryInactiveIcon, IconWithDrawal, IconSupport, Icongroup, CommissionIcon } from "./assets/icons/index"
import Loader from "components/Loader";

const Home = lazy(() => import("./../src/Page/Index"));
const Register = lazy(() => import("./../src/Page/Register"));
const LoginExternal = lazy(() => import("./../src/Page/LoginExternal"));
const LoginInternal = lazy(() => import("./../src/Page/Login"));
const WithdrawalHistory = lazy(() => import('./../src/Page/WithdrawalHistory'));
const RechargeHistory = lazy(() => import('./../src/Page/RechargeHistory'));
const Commission = lazy(() => import('./../src/Page/Commission'));
const BetHistory = lazy(() => import('./../src/Page/BetHistory'));
const Group = lazy(() => import('./Page/Group'));
const SuccessRegister = lazy(() => import("Page/Register/success"));
const ActionHall = lazy(() => import("Page/ActionHall"));
const MediaAction = lazy(() => import("Page/ActionHall/MediaAction"));
const CommissionScale = lazy(() => import("Page/ActionHall/CommissionScale"));
const SafetyCapital = lazy(() => import("Page/ActionHall/SafetyCapital"));
const ActionHall4 = lazy(() => import("Page/ActionHall/ActionHall4"));
const ActionHall5 = lazy(() => import("Page/ActionHall/ActionHall5"));
const Support = lazy(() => import("./../src/Page/Support"));
const Rule = lazy(() => import("./../src/Page/Rule"));
const Profile = lazy(() => import("./../src/Page/Profile"));
const ProFileEdit = lazy(() => import("./../src/Page/Profile/profileEdit"));
const ForgetPass = lazy(() => import("./../src/Page/ForgetPass"));
const Management = lazy(() => import("./Page/Management"));
const Match = lazy(() => import("./../src/Page/Match"));
const MatchDetail = lazy(() => import("./../src/Page/Match/matchDetail"));
const MatchTransactionDetail = lazy(() => import("./../src/Page/Match/transactionDetail"));
const Notifications = lazy(() => import('../src/Page/Notifications'))
const NotificationDetail = lazy(() => import('../src/Page/Notifications/Detail'))
const Transaction = lazy(() => import("./Page/Transaction"));
const Result = lazy(() => import("./Page/Result/resultList"));
const LiveScore = lazy(() => import("./Page/LiveScore"));
const LiveMatch = lazy(() => import("./Page/LiveMatch"));
const Invitation = lazy(() => import("./Page/Invitation"));
const Menu = lazy(() => import("./Page/Menu"));
const Deposit = lazy(() => import('./../src/Page/Deposit'));
const Withdraw = lazy(() => import('./../src/Page/Deposit'));
const Endow = lazy(() => import('./../src/Page/Endow'));

export const routes = {
  successRegister: {
    path: "/register-success",
    component: SuccessRegister,
    isAuth: false,
    label: "",
  },
  register: {
    path: "/register",
    component: Register,
    isAuth: false,
    label: "register",
  },
  actions: {
    path: "/actions",
    component: ActionHall,
    isAuth: true,
    isHeader: false,
    isFooter: false,
    label: "actions",
  },
  mediaAction: {
    path: "/actions/media",
    component: MediaAction,
    isHeader: false,
    isAuth: true,
    label: "actions",
  },
  CommissionScale: {
    path: "/actions/commission",
    isHeader: false,
    component: CommissionScale,
    isAuth: true,
    label: "actions",
  },
  SafetyCapital: {
    path: "/actions/capital",
    isHeader: false,
    component: SafetyCapital,
    isAuth: true,
    label: "actions",
  },
  ActionHall4: {
    path: "/actions-hall/4",
    isHeader: false,
    component: ActionHall4,
    isAuth: true,
    label: "actions",
  },
  ActionHall5: {
    path: "/actions-hall/5",
    isHeader: false,
    component: ActionHall5,
    isAuth: true,
    label: "actions",
  },
  match: {
    path: "/match",
    component: Match,
    isAuth: true,
    label: "match",
    isTop: true
  },
  profile: {
    path: "/management/profile",
    component: Profile,
    isAuth: true,
    label: "me",
    isSubMenu: false,
    icon: <IconProfileIcon />
  },
  profileEdit: {
    path: "/management/profile-edit",
    component: ProFileEdit,
    isAuth: true,
    label: "me",  
    isSubMenu: false,
    icon: <IconProfileIcon />
  },
  matchDetail: {
    path: "/match/:id",
    component: MatchDetail,
    isAuth: true,
    label: "match",
    isTop: false,
    isHeader: false,
    isFooter: false
  },
  matchTransactionDetail: {
    path: "/match-transaction/:id",
    component: MatchTransactionDetail,
    isAuth: true,
    label: "match",
    isHeader: false,
    isTop: false
  },
  managementWallet: {
    path: "/management/wallet",
    component: Management,
    isAuth: true,
    label: "wallet_money",
    isSubMenu: true,
    backurl: "/management",
    icon: <IconWallet />,
    className: "cosutmSelect"
  },
  managementBetHistory: {
    path: "/management/betHistory",
    component: BetHistory,
    isAuth: true,
    label: "history_play",
    isSubMenu: true,
    icon: <BetHistoryInactiveIcon />,
    className: "cosutmSelect"
  },
  managementRechargeHistory: {
    path: "/management/recharge-history",
    component: RechargeHistory,
    isAuth: true,
    label: "history_deposit",
    isSubMenu: true,
    isHeader: false,
    isFooter: false,
    backurl: "/",
    icon: <IconRechargeHistory />
  },
  managementWithDrawalHistory: {
    path: "/management/withdrawal-history",
    component: WithdrawalHistory,
    isAuth: true,
    label: "history_withdraw",
    isSubMenu: true,
    isHeader: false,
    isFooter: false,
    backurl: "/",
    icon: <IconWithDrawal />
  },
  managementCommission: {
    path: "/management/commission",
    component: Commission,
    isAuth: true,
    isHeader: false,
    isFooter: false,
    label: "commission",
    isSubMenu: false,
    icon: <CommissionIcon />,
    className: "cosutmSelect"
  },
  managementGroup: {
    path: "/management/group",
    component: Group,
    isAuth: true,
    label: "managementGroup",
    isSubMenu: false,
    isHeader: false,
    isFooter: false,
    icon: <Icongroup />
  },
  support: {
    path: "/management/support",
    component: Support,
    isAuth: true,
    label: "support",
    isSubMenu: true,
    icon: <IconSupport />,
    backurl: "/",
    isHeader: false,
    isFooter: false,

  },
  management: {
    path: "/management",
    component: Management,
    isAuth: true,
    label: "management",
    isSubMenu: true,
    className: "cosutmSelect"
  },
  transaction: {
    path: "/transaction",
    component: Transaction,
    isAuth: true,
    label: "transaction",
    className: "cosutmSelect"
  },
  home: {
    path: "/",
    component: Home,
    isAuth: true,
    label: "homepage",
  },
  rule: {
    path: "/rule",
    component: Rule,
    isAuth: true,
    isSubMenu: false,
    isHeader: false,
    isFooter: false,
    label: "rule_daily",
  },
  notifications: {
    path: "/notifications",
    component: Notifications,
    isAuth: true,
    isSubMenu: true,
    label: "notifications",
    backurl: "/",
    isHeader: false,
    isFooter: false,
  },
  endowPost: {
    path: "/endowPost",
    component: Endow,
    isAuth: true,
    isSubMenu: true,
    label: "endowPost",
    backurl: "/",
    isHeader: false,
    isFooter: false,
  },
  notificationDetail: {
    path: "/notifications/:id",
    component: NotificationDetail,
    isAuth: true,
    isSubMenu: true,
    label: "Notification_detail",
    backurl: "/notifications",
    isHeader: false,
    isFooter: false,

  },
  resultList: {
    path: "/resultList",
    component: Result,
    isAuth: true,
    isSubMenu: true,
    label: "resultList",
    isHeader: false,
    isFooter: false,
  },
  liveScore: {
    path: "/liveScore",
    component: LiveScore,
    isAuth: true,
    isSubMenu: true,
    label: "liveScore",
  },
  liveMatch: {
    path: "/liveMatch",
    component: LiveMatch,
    isAuth: true,
    isSubMenu: true,
    label: "liveMatch",
  },
  invitation: {
    path: "/invitation",
    component: Invitation,
    isAuth: true,
    isSubMenu: true,
    label: "invitation",
    isHeader: false,
    isFooter: false,
  },
  menu: {
    path: "/menu",
    isAuth: true,
    isHeader: false,
    isFooter: false,
    component: Menu
  },
  deposit: {
    path: "/deposit",
    component: Deposit,
    isHeader: false,
    isFooter: false,
    label: "Deposit"
  },
  withdraw: {
    path: "/withdraw",
    component: Withdraw,
    isHeader: false,
    isFooter: false,
    tabKey: 'withdraw',
    label: "Withdraw"
  }
}
function App(props) {
  const { isUserLoggedIn, appUserId } = props
  const dispatch = useDispatch()

  function handleGetUserById(appUserId) {
    if (appUserId) {
      AppUsersAppUsers.getDetailUserById({
        id: appUserId
      }).then(result => {
        const { isSuccess, data } = result
        if (isSuccess) {
          dispatch(handleUpdateDetail(data))
        }
      })
    }
  }

  useEffect(() => {
    if (appUserId) {
      handleGetUserById(appUserId)
    }
  }, [])

  return (
    <>
      <Router>
        <Suspense fallback={<div className="spin-loading"><Loader /></div>}>
          <Switch>

            {Object.keys(routes).map((key, index) => {
              if (isUserLoggedIn && routes[key].isAuth) {
                return <Route key={index} extract exact path={routes[key].path} component={(props) => <Layout  isAuth={routes[key].isAuth} {...props} tabKey={routes[key].tabKey} isSubMenu={routes[key].isSubMenu} isFooter={routes[key].isFooter} isHeader={routes[key].isHeader} Component={routes[key].component} className={`${routes[key].className || 'HOME'}`} />}/>
              } else if (!routes[key].isAuth) {
                return <Route key={index} extract exact path={routes[key].path} component={(props) => <Layout  isAuth={routes[key].isAuth} {...props} tabKey={routes[key].tabKey} Component={routes[key].component} className={routes[key].isHeader ? "HOME" : "LOGIN"} />}/>
              }
            })}
            <Route exact path="/forgot-password" component={(props) => <Layout  {...props} Component={ForgetPass} className="" />} />
            {/* Link nay chi duoc su dung o internal, khong duoc redirect tu bat ky trang nao */}
            <Route exact path="/login" component={(props) => <Layout  {...props} Component={LoginInternal} />} />
            {/* Link nay chi duoc su dung o internal, khong duoc redirect tu bat ky trang nao */}
            <Route exact path="/register" component={(props) => <Layout  {...props} Component={Register} />} />
            {/* <Route exact path="/" component={(props) => <Layout  {...props} Component={Home} />} /> */}
            <Route component={(props) => <Layout  {...props} Component={LoginInternal
            } className="" />} />
          </Switch>
        </Suspense>
      </Router>

    </>
  );
}
const mapStateToProps = state => ({
  isUserLoggedIn: state.member ? state.member.isUserLoggedIn : false,
  appUserId: state.member ? state.member.appUserId : null,
});

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
