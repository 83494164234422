
import { GET_BET_LIST, GET_BET_LIST_SUCCESS, GET_BET_LIST_FAIL } from '../constants/bet'
let initialState = {
  data: [],
  total: null,
  isLoading: false,
  error: null, 
}
export default function matchReducer(state = initialState, action) {

  switch (action.type) {
    case GET_BET_LIST_SUCCESS: {
      return {
        ...state,
        ...action.data,
        isLoading: false,
      }
    }
    case GET_BET_LIST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_BET_LIST_FAIL: {
      return {
        ...state,
        error: action.data,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

