import Request from './request'

export default class BetService {

    static async getDetail(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'BetRecords/user/getDetail',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
    static async getList(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'BetRecords/user/getList',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
    static async placeRecord(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'BetRecords/user/placeRecord',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
    static async placeFakeRecord(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'BetRecords/user/placeFakeRecord',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
    static async getListPublic(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'LeaderBoard/user/getTopRank',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
    static async get24hProfit(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: 'BetRecords/user/sumaryWinLoseAmount',
                data
            }).then((result = {}) => {
                const { statusCode, data, message } = result

                if (statusCode === 200) {
                    return resolve({ isSuccess: true, data })
                } else {
                    return resolve({ isSuccess: false, message })
                }
            })
        })
    }
}