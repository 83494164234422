
import { SYSTEM_CONFIG } from '../constants/system'

const initialState={}
export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case SYSTEM_CONFIG: {
      if (action.data) {
        return {
          ...state,
          ...action.data,
        }
      }
      return {}
    }
    default:
      return state
  }
}

