export const GET_LIST = "MATCH_GET_LIST";
export const GET_BET_LIST = "BET_GET_LIST";
export const LIST_AMOUNT = [
    { label: 'All', amount: null }, { label: '20000', amount: 20000 }, { label: '50000', amount: 50000 }, { label: '100000', amount: 100000 }, { label: '200000', amount: 200000 }, { label: '500000', amount: 500000 }, { label: '1000000', amount: 1000000 }, { label: '2000000', amount: 2000000 }, { label: '5000000', amount: 5000000 }, { label: '10000000', amount: 10000000 }, { label: '50000000', amount: 50000000 },];
export const LIST_AMOUNT_USD = [
    { label: 'All', amount: null }, { label: '20', amount: 20 }, { label: '50', amount: 50 }, { label: '100', amount: 100 }, { label: '200', amount: 200 }, { label: '500', amount: 500 }, { label: '1000', amount: 1000 }, { label: '2000', amount: 2000 }, { label: '5000', amount: 5000 }, { label: '10000', amount: 10000 }, { label: '50000', amount: 50000 },];
export const GAME_STATUS = {
    New: "not_yet_playing",
    Waiting: "waiting",
    Pending: "postponing",
    Playing: "playing",
    Completed: "played",
    Deleted: "deleted",
    Canceled: "canceled",
}