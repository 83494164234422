import Request from "./request";

export default class AppUsers {
  static async getDetailUserById(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/getDetailUserById",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }

  static async changePasswordUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/changePasswordUser",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }

  static async updateInfoUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: "POST",
        path: "AppUsers/updateInfoUser",
        data,
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }

  static async summaryUser(data = {}) {
    return new Promise((resolve, reject) => {
      Request.send({
        method: "POST",
        path: "BetRecords/user/summaryAll",
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result;

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    })
  }
}
