
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import store from './store'
import "./styles/index.scss"
import 'antd/dist/antd.css'; 
import "./Page/Index/index.scss"
import "./Page/ExchangeHistory/index.scss"
import "./Page/ForgetPass/index.scss"
import "./Page/Introdure/index.scss";
import "./Page/ListCoinPrice/index.scss";
import "./Page/Management/index.scss"
import "./Page/Login/index.scss"
import "./Page/Packet/index.scss"
import "./Page/Profile/index.scss"
import "./Page/PurchaseHistory/index.scss"
import "./Page/Recharge/index.scss"
import "./Page/RechargeHistory/index.scss"
import "./Page/Register/index.scss"
import "./Page/Support/index.scss"
import "./Page/TransactionRequest/index.scss"
import "./Page/WithdrawalHistory/index.scss"
import { IntlProviderWrapper } from 'helper/Internationalization';
import { UserProvider } from 'context/UserContext';

ReactDOM.render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <UserProvider>
        <App />
      </UserProvider>
    </IntlProviderWrapper>
  </Provider>,
  document.getElementById('root')
);

