import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import bet from './bet'
import system from './system'
const rootReducer = combineReducers({
  member,
  app,
  bet,
  system
})

export default rootReducer